<template>
  <v-card data-cy="vehicle-form">
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Vehicles
      </h5>
    </v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs7>
          <p class="title font-weight-bold text-xs-left">
            Add Vehicle
          </p>
          <v-form>
            <v-text-field
              v-model="newVehicle.year"
              :error-messages="numericFieldErrors($v.newVehicle.year)"
              label="Vehicle Year"
              clearable
              mask="####"
              @input="$v.newVehicle.year.$touch()"
              @blur="$v.newVehicle.year.$touch()"
              data-cy="year"
            />

            <v-text-field
              v-model="newVehicle.make"
              label="Vehicle Make"
              :error-messages="textFieldErrors($v.newVehicle.make)"
              clearable
              @input="$v.newVehicle.make.$touch()"
              @blur="$v.newVehicle.make.$touch()"
              data-cy="make"
            />

            <v-text-field
              v-model="newVehicle.model"
              label="Vehicle Model"
              :error-messages="textFieldErrors($v.newVehicle.model)"
              clearable
              @input="$v.newVehicle.model.$touch()"
              @blur="$v.newVehicle.model.$touch()"
              data-cy="model"
            />

            <p class="title font-weight-bold text-xs-left mt-1">
              Will This Vehicle Require Overnight Parking?
            </p>
            <v-radio-group
              v-model="newVehicle.overnight"
            >
              <v-radio
                label="Yes"
                :value="true"
                data-cy="overnight-yes"
              />
              <v-radio
                label="No"
                :value="false"
                data-cy="overnight-no"
              />
            </v-radio-group>

            <v-text-field
              v-model="newVehicle.notes"
              label="Additional Vehicle & Storage Notes"
              :error-messages="textFieldOptionalErrors($v.newVehicle.notes)"
              clearable
              @input="$v.newVehicle.notes.$touch()"
              @blur="$v.newVehicle.notes.$touch()"
              data-cy="notes"
            />
          </v-form>
        </v-flex>

        <v-flex xs5>
          <v-layout
            wrap
            justify-space-around
          >
            <v-flex
              xs5
              v-for="(vehicle, ind) in vehicles"
              :key="ind"
            >
              <equipment-tile
                class="mb-3"
                :top-text="vehicle.make"
                :middle-text="vehicle.model"
                :bottom-text="vehicle.year.toString()"
                @remove="removeItem(ind)"
              >
                <v-btn
                  slot="action"
                  @click="removeItem(ind)"
                  data-cy="remove"
                >
                  Remove
                </v-btn>
              </equipment-tile>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        :disabled="$v.$invalid"
        @click="addItem()"
        data-cy="add"
      >
        Add Vehicle
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { validationMixin } from '../validators'
import { maxLength, numeric, required } from 'vuelidate/lib/validators'
import EquipmentTile from './EquipmentTile'
export default {
  mixins: [validationMixin],
  components: {
    EquipmentTile
  },
  data () {
    return {
      newVehicle: {
        year: null,
        make: '',
        model: '',
        overnight: null,
        notes: ''
      }
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'addVehicle',
      'removeVehicle'
    ]),
    addItem () {
      this.addVehicle({ ...this.newVehicle })
    },
    removeItem (index) {
      this.removeVehicle(index)
    }
  },
  computed: {
    ...mapState('reservation', {
      vehicles: state => state.reservationForm.vehicles
    })
  },
  validations: {
    newVehicle: {
      year: {
        required,
        numeric,
        maxLength: maxLength(4)
      },
      make: {
        required,
        maxLength: maxLength(256)
      },
      model: {
        required,
        maxLength: maxLength(256)
      },
      overnight: {
        required
      },
      notes: {
        maxLength: maxLength(256)
      }
    }
  }
}
</script>

<style>

</style>
