<template>
  <v-card data-cy="contact-form">
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Reservation Information
      </h5>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-lg>
        <v-layout wrap>
          <v-flex
            xs7
            v-if="!onlyAdditionalContacts"
          >
            <h3 class="title font-weight-bold text-xs-left mt-2">
              Reservation Details
            </h3>
            <v-form @submit.prevent>
              <v-text-field
                v-model="reservationDesc"
                :error-messages="textFieldErrors($v.reservationDesc)"
                label="Reservation Title"
                clearable
                required
                @input="$v.reservationDesc.$touch()"
                @blur="$v.reservationDesc.$touch()"
                data-cy="title"
              />
            </v-form>
          </v-flex>
          <v-flex
            xs7
            v-if="!onlyAdditionalContacts"
          >
            <h3 class="title font-weight-bold text-xs-left mt-2">
              Primary Team Member
            </h3>
            <v-form>
              <v-text-field
                v-model="fullName"
                :error-messages="nameFieldErrors($v.fullName)"
                label="Full Name"
                hint="ex. John Doe"
                required
                clearable
                @input="$v.fullName.$touch()"
                @blur="$v.fullName.$touch()"
                data-cy="name"
              />

              <v-text-field
                v-model="companyName"
                :error-messages="companyFieldErrors($v.companyName)"
                label="Company"
                hint="ex. Mcity (If University of Michigan, please explicity state 'University of Michigan')"
                required
                clearable
                @input="$v.companyName.$touch()"
                @blur="$v.companyName.$touch()"
                data-cy="companyname"
              />
              <v-text-field
                v-model.trim="email"
                :error-messages="emailFieldErrors($v.email)"
                label="Email Address"
                hint="ex. uniqname@umich.edu"
                required
                clearable
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                data-cy="email"
              />

              <v-text-field
                v-model="phoneNum"
                :error-messages="numericFieldErrors($v.phoneNum)"
                label="Phone Number"
                hint="ex. 5550123456"
                mask="phone"
                required
                clearable
                @input="$v.phoneNum.$touch()"
                @blur="$v.phoneNum.$touch()"
                data-cy="phone"
              />
            </v-form>
          </v-flex>
          <v-flex xs7>
            <v-text-field
              v-model="numOfAttendees"
              :error-messages="minValueNumericFieldErrors($v.numOfAttendees)"
              label="Total Number of Team Members/Atendees"
              required
              @input="updateContacts($v.numOfAttendees)"
              type="number"
              data-cy="num-of-attendees"
              :min="1"
            />
            <div :class="{'mt-5': !onlyAdditionalContacts}">
              <h3
                class="title font-weight-bold text-xs-left"
              >
                Additional Team Members (*optional)
              </h3>
              <h5 class="text-capitalize font-weight-light font-italic text-xs-left mt-2 mb-4">
                Every additional contact must complete track training prior to the scheduled reservation date.
              </h5>
            </div>
            <div v-for="(contact, i) in additionalContacts" :key="i">
              <v-form>
                <div :class="additionalContacts.length > 1 ? 'new_contact_box' : ''">
                  <v-text-field
                    v-model="additionalContacts[i].name"
                    :error-messages="nameFieldErrors($v.additionalContacts.$each[i].name)"
                    label="Contact Full Name"
                    hint="ex. John Doe"
                    required
                    clearable
                    @input="$v.additionalContacts.$each[i].name.$touch()"
                    @blur="$v.additionalContacts.$each[i].name.$touch()"
                    data-cy="addl-name"
                  />

                  <v-text-field
                    v-model.trim="additionalContacts[i].email"
                    :error-messages="emailFieldErrors($v.additionalContacts.$each[i].email)"
                    label="Contact Email Address"
                    hint="ex. uniqname@umich.edu"
                    required
                    clearable
                    @input="$v.additionalContacts.$each[i].email.$touch()"
                    @blur="$v.additionalContacts.$each[i].email.$touch()"
                    data-cy="addl-email"
                  />

                  <v-text-field
                    v-model="additionalContacts[i].phone"
                    :error-messages="numericFieldErrors($v.additionalContacts.$each[i].phone)"
                    label="Contact Phone Number"
                    hint="ex. 5550123456"
                    mask="phone"
                    required
                    clearable
                    @input="$v.additionalContacts.$each[i].phone.$touch()"
                    @blur="$v.additionalContacts.$each[i].phone.$touch()"
                    data-cy="addl-phone"
                  />
                </div>
              </v-form>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { numeric, required, maxLength, email, minValue } from 'vuelidate/lib/validators'
import { validationMixin, name, company } from '../validators.js'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {

  props: {
    onlyAdditionalContacts: {
      type: Boolean,
      default: false
    }
  },
  mixins: [validationMixin],
  data () {
    return {
      contacts: [{
        name: 1,
        email: '',
        phone: '',
        training: false
      }],
      newContact: {
        name: '',
        email: '',
        phone: '',
        training: false
      },
      numOfAttendees: 1
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'setReservationField',
      'addAdditionalContact',
      'removeAdditionalContact',
      'setNumOfAttendees'
    ]),
    ...mapActions('reservation/transactions', [
      'addAdditionalContactAction'
    ]),
    createPayload (field, data) {
      return { field, data }
    },
    addItem (newContact) {
      this.addAdditionalContactAction({ ...newContact })
    },
    removeItem (index) {
      this.removeAdditionalContact(index)
    },
    updateContacts (vuelidateValidation) {
      vuelidateValidation.$touch()
      const prevNumOfAttendees = this.additionalContacts.length
      this.setNumOfAttendees(this.numOfAttendees)
      if (prevNumOfAttendees < this.numOfAttendees) {
        for (let i = prevNumOfAttendees; i < Math.min(this.numOfAttendees, 10); i++) {
          this.addAdditionalContact({
            name: '',
            email: '',
            phone: '',
            training: false
          })
        }
      } else {
        if (this.numOfAttendees < 10) {
          for (let i = prevNumOfAttendees; i > this.numOfAttendees; i--) {
            if (this.additionalContacts.length > 1) {
              this.removeAdditionalContact()
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState('reservation', {
      additionalContacts: state => state.reservationForm.addl_contacts,
      reservationNumOfAttendees: state => state.reservationForm.num_of_attendees
    }),
    fullName: {
      get () {
        return this.$store.state.reservation.reservationForm.primary_name
      },
      set (value) {
        this.setReservationField(this.createPayload('primary_name', value))
      }
    },
    companyName: {
      get () {
        return this.$store.state.reservation.reservationForm.company_name
      },
      set (value) {
        this.setReservationField(this.createPayload('company_name', value))
      }
    },
    email: {
      get () {
        return this.$store.state.reservation.reservationForm.primary_email
      },
      set (value) {
        this.setReservationField(this.createPayload('primary_email', value))
      }
    },
    phoneNum: {
      get () {
        return this.$store.state.reservation.reservationForm.primary_phone || ''
      },
      set (value) {
        this.setReservationField(this.createPayload('primary_phone', value))
      }
    },
    reservationDesc: {
      get () {
        return this.$store.state.reservation.reservationForm.reservation_desc
      },
      set (value) {
        this.setReservationField(this.createPayload('reservation_desc', value))
      }
    },
    primaryValidity () {
      return this.$v.phoneNum.$invalid ||
        this.$v.email.$invalid ||
        this.$v.companyName.$invalid ||
        this.$v.fullName.$invalid ||
        this.$v.reservationDesc.$invalid ||
        this.$v.numOfAttendees.$invalid ||
        this.additionalContacts[0].name || this.additionalContacts[0].email || this.additionalContacts[0].phone ? this.$v.additionalContacts.$invalid : false
    }
  },
  mounted () {
    if (this.$route.path.split('/').slice(-1)[0] === 'create') {
      // Grab user's credentials
      const fullName = this.$store.getters.getFullName
      const email = this.$store.getters.getUser.username
      this.setReservationField(this.createPayload('primary_name', fullName))
      this.setReservationField(this.createPayload('primary_email', email))
    }
  },
  validations: {
    additionalContacts: {
      $each: {
        name: {
          required,
          name,
          maxLength: maxLength(100)
        },
        email: {
          required,
          email,
          maxLength: maxLength(100)
        },
        phone: {
          numeric,
          required,
          maxLength: maxLength(10)
        }
      }
    },
    fullName: {
      required,
      name,
      maxLength: maxLength(100)
    },
    companyName: {
      required,
      company,
      maxLength: maxLength(200)
    },
    email: {
      required,
      email,
      maxLength: maxLength(100)
    },
    phoneNum: {
      numeric,
      required,
      maxLength: maxLength(10)
    },
    numOfAttendees: {
      numeric,
      required,
      minValue: minValue(1)
    },
    reservationDesc: {
      required,
      maxLength: maxLength(200)
    }
  },
  watch: {
    primaryValidity: {
      immediate: true,
      handler (value) {
        this.$emit('validation', {
          key: 'contact',
          value
        })
      }
    }
  }
}
</script>

<style>
.new_contact_box {
  outline: 2px solid gray;
  border-radius: 2px; padding: 15px; margin-top: 20px;
}
</style>
