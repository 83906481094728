<template>
  <v-hover>
    <v-card>
      <v-responsive aspect-ratio="1">
        <div class="component-wrapper">
          <v-card-text class="component-spacer">
            <div class="component-text">
              {{ topText }}
            </div>
            <div class="component-text">
              {{ middleText }}
            </div>
            <div class="component-type">
              {{ bottomText }}
            </div>
          </v-card-text>
          <slot name="action" />
        </div>
      </v-responsive>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'black'
    },
    topText: {
      type: String,
      required: false,
      default: ''
    },
    middleText: {
      type: String,
      required: false,
      default: ''
    },
    bottomText: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped>
.component-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.component-spacer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}

.component-text {
  font-size: 1.5rem;
}

.component-type {
  color:rgb(71, 71, 71);
}
</style>
