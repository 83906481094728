<template>
  <v-card
    class="my-2"
    data-cy="safety-plan"
  >
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Safety Procedure Plan
      </h5>
    </v-card-title>
    <v-card-text class="text-xs-left">
      <v-alert
        :value="!isCreation"
        type="warning"
      >
        If the safety plan has not changed, you may leave this field blank.
      </v-alert>
      <span>Please upload a PDF of your Testing Safety Plan. At a minimum it should address the following concerns:</span>
      <v-list dense>
        <v-list-tile
          v-for="(item, ind) in conditions"
          :key="ind"
        >
          <v-list-tile-action>
            <v-icon
              small
              color="black"
            >
              fa-circle
            </v-icon>
          </v-list-tile-action>
          <v-list-tile-content>{{ item }}</v-list-tile-content>
        </v-list-tile>
      </v-list>
      <span>
        This plan will be reviewed by Mcity staff before your reservation date.
        You may be asked to make revisions before your booking can be confirmed.
      </span>
    </v-card-text>

    <v-flex
      xs6
      class="px-2"
    >
      <v-text-field
        v-model="filename"
        label="Upload file below"
        disabled
      />
    </v-flex>
    <v-card-actions>
      <upload-button
        :file-changed-callback="onFileSelect"
        title="Browse"
        accept=".pdf"
        class="primary white--text"
        :ripple="false"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import UploadButton from 'vuetify-upload-button'

export default {
  components: {
    UploadButton
  },
  data () {
    return {
      filename: '',
      conditions: [
        'Safety of all in-vehicle participants of a test.',
        'Safety of all out-of-vehicle participants of a test.',
        'An analysis of risks (speed, complex coordination, autonomy, etc).',
        'Mitigations in place to reduce risks to an acceptable level.',
        'General safety of the vehicle, facility, etc, assuming loss of control at any point.'
      ]
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'setReservationField',
      'setSafetyPlan'
    ]),
    createPayload (field, data) {
      return { field, data }
    },
    onFileSelect (file) {
      this.$v.filename.$touch()
      if (file != null) {
        this.setSafetyPlan(file)
        this.filename = file.name
      }
    }
  },
  computed: {
    isCreation () {
      return this.$route.name === 'Create'
    }
  },
  validations: {
    filename: {
      required: requiredIf('isCreation')
    }
  },
  watch: {
    '$v.$invalid': {
      immediate: true,
      handler (value) {
        this.$emit('validation', {
          key: 'safety',
          value
        })
      }
    }
  }
}
</script>

<style>

</style>
