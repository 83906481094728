<template>
  <div data-cy="preview">
    <v-card :color="!eventID && disablable ? '#d3d3d3' : 'white'">
      <v-card-title class="primary headline white--text">
        <div>Reservation Preview</div>
        <v-spacer />
        <v-btn
          v-if="!readonly"
          :disabled="!saving && (!modified || unableToSave)"
          @click="saveChanges"
          data-cy="preview-save"
        >
          <div v-if="!saving">
            Save
          </div>
          <v-progress-circular
            indeterminate
            v-else-if="saving"
            color="primary"
          />
        </v-btn>
        <div>{{ reservationKey }}</div>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        class="ma-0"
        indeterminate
      />
      <v-card-text v-if="reservationCopy">
        <v-container grid-list-lg>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md6
              style="justify-content: space-between; display: flex; flex-direction: column;"
            >
              <div>
                <h2 class="text-xs-left">
                  Reservation Detail
                </h2>
                <!-- reservation_desc is only generated for reservations createdthru the app, -->
                <!-- and is checked in a few areas to test if the event was created from gcal -->
                <v-text-field
                  v-model="reservation.reservation_desc"
                  label="Reservation Title"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-title"
                />
                <h2 class="text-xs-left">
                  Primary Contact
                </h2>
                <v-text-field
                  v-model="reservation.primary_name"
                  label="Contact Name"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-name"
                />
                <v-text-field
                  v-model="reservation.company_name"
                  label="Company"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-company"
                />
                <v-text-field
                  v-model="reservation.primary_email"
                  label="Contact Email Address"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-email"
                />
                <v-text-field
                  v-model="reservation.primary_phone"
                  label="Contact Phone Number"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-phone"
                />
                <h2 class="text-xs-left">
                  Facility Configuration
                </h2>
                <v-text-field
                  v-model="reservation.facility_prep"
                  label="Facility Prep (ex. Don't salt, leave snow)"
                  :readonly="readonly"
                  data-cy="preview-prep"
                />
                <v-text-field
                  v-model="reservation.facility_its"
                  label="Facility ITS (ex. DSRC Enabled)"
                  :readonly="readonly"
                  data-cy="preview-its"
                />
                <v-text-field
                  v-model="reservation.data_collection"
                  label="Data Collection (ex. Cameras enabled, api access)"
                  :readonly="readonly"
                  data-cy="preview-data"
                />
                <v-checkbox
                  v-model="reservation.guest_office"
                  label="Guest Office Access"
                  class="mt-0"
                  :readonly="readonly"
                  data-cy="preview-office"
                />

                <h2 class="text-xs-left">
                  Technician Support*
                </h2>

                <v-text-field
                  v-model="reservation.technician_support"
                  data-cy="technician"
                  label="Technician Support (ex. Boom Lift, Cone/Barrier Setup, LPRV Operation)"
                  :readonly="readonly"
                />

                <v-checkbox
                  v-model="reservation.hardware_install"
                  label="Hardware Installation/Removal Required"
                  data-cy="hardware_install"
                  :readonly="readonly"
                />

                <v-text-field
                  v-model="reservation.hardware_desc"
                  data-cy="hardware_desc"
                  label="Install Details (Type, Power/Data requirements, Proposed Location, etc.)"
                  :disabled="!reservation.hardware_install"
                  :readonly="readonly"
                />

                <v-text-field
                  v-model="reservation.tech_time"
                  data-cy="tech_time"
                  label="Tech Time Worked"
                  :readonly="readonly"
                  v-if="admin"
                />

                <p style="align: left">
                  *Usage rates apply. See <a href="https://mcity.umich.edu/book/">here for details</a>.
                </p>
              </div>

              <div class="" v-if="!admin">
                <h2 class="text-xs-left">
                  Facility Rental Estimate
                </h2>
                <v-list v-if="reservation">
                  <div class="" style="text-align: left;">
                    Pricing for {{ billableHours }} hour period.
                  </div>
                  <div class="" style="justify-content: space-between; flex-direction: row; display: flex; padding: 1rem 1rem 0rem 1rem;">
                    <div class="estimate-name">
                      Item
                    </div>
                    <div class="estimate">
                      / hr
                    </div>
                    <div class="estimate">
                      subtotal
                    </div>
                  </div>
                  <v-list-tile
                    v-for="(date, ind) in dateRange"
                    :key="ind"
                    data-cy="preview-equipment"
                  >
                    <div class="line-item-wrapper">
                      <div class="estimate-name" style="">
                        {{ date }}  {{ reservation.partial_reservation === "Full" ? " - Whole Track" : ` - ${reservation.partial_reservation}` }} {{ `- ${increment}hr` }}
                      </div>
                      <div class="estimate">
                        {{ reservationRates && reservationRates.length ?
                          `$${((reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) || {}).rate_amount || 0).toFixed(2)}` :
                          'missing rate'
                        }}
                      </div>
                      <div class="estimate">
                        {{ reservationRates && reservationRates.length ?
                          `$${(((reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) || {}).rate_amount || 0) * increment).toFixed(2)}` :
                          'missing rate'
                        }}
                      </div>
                    </div>
                  </v-list-tile>

                  <div
                    class="total-wrapper"
                    style="border-bottom: 1px dotted rgba(0, 0, 0)"
                  >
                    <div class="bold estimate-name">
                      Rental Cost
                    </div>
                    <div class="bold estimate">
                      {{ reservationRates && reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) ?
                        `$${(reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)).rate_amount * duration).toLocaleString('en-US', { minimumFractionDigits: 2 })}` :
                        'missing rate'
                      }}
                    </div>
                    <div class="bold estimate">
                      {{ reservationRates && reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) ?
                        `$${((reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation))).rate_amount * billableHours).toLocaleString('en-US', { minimumFractionDigits: 2 })}` :
                        'missing rate'
                      }}
                    </div>
                  </div>
                </v-list>
              </div>
            </v-flex>

            <v-flex
              xs12
              md6
              class="text-xs-left"
              style="display: flex; flex-direction: column; justify-content: space-between;"
            >
              <div class="">
                <h2>Billing Info</h2>
                <v-text-field
                  v-model="reservation.billing_name"
                  label="Billing Name"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-billing-name"
                />
                <v-text-field
                  v-model="reservation.billing_email"
                  label="Billing Email Address"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-billing-email"
                />
                <v-text-field
                  v-model="reservation.billing_phone"
                  label="Billing Phone Number"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-billing-phone"
                />
                <v-text-field
                  v-model="reservation.billing_address"
                  label="Billing Address"
                  :readonly="readonly"
                  :rules="[rules.required]"
                  data-cy="preview-billing-address"
                />
                <v-text-field
                  v-model="reservation.billing_info"
                  label="Additional billing info (Short code, vendor ID, Account #)"
                  :readonly="readonly"
                  data-cy="preview-billing-info"
                />
                <v-checkbox
                  v-model="reservation.grant_funded"
                  label="Reservation is Grant Funded"
                  data-cy="grant-funded"
                  :readonly="readonly"
                />

                <v-select
                  v-model="reservation.grant_key"
                  :items="grants"
                  item-text="grant_cd"
                  item-value="grant_key"
                  label="Grant Funding Source"
                  data-cy="grant-picker"
                  :readonly="readonly || !reservation.grant_funded"
                />
                <div class="additional-contacts">
                  <h2>Additional Contacts</h2>
                  <h3>Total Number of Attendees: {{ reservation.num_of_attendees }}</h3>
                  <v-list
                    two-line
                    v-if="
                      reservation.addl_contacts &&
                        reservation.addl_contacts.length > 0
                    "
                  >
                    <v-list-tile
                      v-for="(contact, ind) in reservation.addl_contacts"
                      :key="ind"
                      data-cy="preview-contact"
                    >
                      <v-list-tile-content v-if="contact.name && contact.email">
                        <v-list-tile-title>{{ contact.name }}</v-list-tile-title>
                        <v-list-tile-sub-title>
                          {{
                            contact.email
                          }}
                        </v-list-tile-sub-title>
                        <v-list-tile-sub-title v-if="contact.phone">
                          {{
                            maskPhoneNum(contact.phone)
                          }}
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                  <div
                    v-else
                    class="text-xs-left"
                  >
                    No additional contacts added.
                  </div>
                  <v-btn
                    v-if="!readonly"
                    :disabled="reservationKey === null"
                    @click="showContactsDialog = true"
                    data-cy="preview-edit-contacts"
                  >
                    edit contacts
                  </v-btn>
                </div>
              </div>
              <div class="vehicles-wrap">
                <h2 class="mt-2">
                  Vehicles
                </h2>
                <v-list
                  two-line
                  v-if="reservation.vehicles && reservation.vehicles.length > 0"
                >
                  <v-list-tile
                    v-for="(vehicle, ind) in reservation.vehicles"
                    :key="ind"
                    data-cy="preview-vehicle"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>{{ vehicle.make }}</v-list-tile-title>
                      <v-list-tile-sub-title>
                        {{
                          vehicle.model
                        }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title>
                        {{
                          vehicle.year
                        }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <div
                  v-else
                  class="text-xs-left"
                >
                  No vehicles added.
                </div>
                <v-btn
                  v-if="!readonly"
                  :disabled="reservationKey === null"
                  @click="showVehicleDialog = true"
                  data-cy="preview-edit-vehicles"
                >
                  edit vehicles
                </v-btn>
              </div>
              <div class="safety-plan">
                <h2>
                  Safety Plan
                </h2>

                <FileDownload
                  v-if="safetyPlan && (workingSafetyPlan.name === safetyPlan.media_name || workingSafetyPlan.media_name === safetyPlan.media_name)"
                  :url="
                    `/api/reservation/${eventID}/media/${safetyPlan.media_url}`
                  "
                  :filename="safetyPlan.media_name"
                />
                <div
                  v-else-if="workingSafetyPlan"
                >
                  {{ workingSafetyPlan.name }}
                </div>
                <div v-else>
                  No safety plan
                </div>
                <div>
                  <v-btn
                    v-if="!readonly"
                    :disabled="reservationKey === null"
                    @click="showSafetyPlanDialog = true"
                    data-cy="preview-edit-safety-plan"
                  >
                    Edit Safety Plan
                  </v-btn>
                </div>
              </div>
              <div class="equipment-rental" v-if="!admin">
                <h2 class="text-xs-left">
                  Equipment Rental Estimate
                </h2>

                <v-list v-if="reservation.reservation_equipment">
                  <div class="">
                    Pricing for {{ billableHours }} hour period.
                  </div>
                  <div class="" style="justify-content: space-between; flex-direction: row; display: flex; padding: 1rem 1rem 0rem 1rem;">
                    <div class="estimate-name">
                      Item
                    </div>
                    <div class="estimate">
                      / hr
                    </div>
                    <div class="estimate">
                      subtotal
                    </div>
                  </div>
                  <v-list-tile
                    v-for="(cost, ind) in reservation.reservation_equipment_cost"
                    :key="ind"
                    data-cy="preview-equipment"
                  >
                    <div class="line-item-wrapper">
                      <div class="estimate-name">
                        {{ ind }}
                      </div>
                      <div class="estimate">
                        ${{ cost.toFixed(2) }}
                      </div>
                      <div class="estimate">
                        ${{ (cost * billableHours).toFixed(2) }}
                      </div>
                    </div>
                  </v-list-tile>

                  <div
                    class="total-wrapper" style="border-bottom: 1px dotted rgba(0, 0, 0)"
                  >
                    <div class="bold estimate-name">
                      Equipment Cost
                    </div>
                    <div class="bold estimate">
                      {{ `$${getTotalCost().toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                    </div>
                    <div class="bold estimate">
                      {{ `$${(getTotalCost() * billableHours).toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                    </div>
                  </div>
                </v-list>
                <div
                  v-else
                  class="text-xs-left"
                >
                  No equipment added.
                </div>
                <v-btn
                  v-if="!readonly"
                  :disabled="reservationKey === null"
                  @click="showEquipmentDialog = true"
                  style="float:left;"
                  data-cy="preview-edit-equipment"
                >
                  edit equipment
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <div class="equipment-rental" v-if="!admin">
            <h2 class="text-xs-left">
              Total Reservation Estimate
            </h2>

            <v-list v-if="reservation.reservation_equipment_cost">
              <div class="" style="text-align:left">
                Pricing for {{ billableHours }} hour period.
              </div>
              <div class="" style="justify-content: space-between; flex-direction: row; display: flex; padding: 1rem 1rem 0rem 1rem;">
                <div class="estimate-name">
                  Item
                </div>
                <div class="estimate">
                  / hr
                </div>
                <div class="estimate">
                  subtotal
                </div>
              </div>
              <div class="" style="justify-content: space-between; flex-direction: row; display: flex; padding: 1rem 1rem 0rem 1rem; font-size: 16px; border-bottom: 1px dotted lightgray;">
                <div class="estimate-name">
                  {{ dateRange ? `${dateRange[0]} - ${dateRange.slice(-1)}` : 'unknown date range' }} - Facility Rental Estimate
                </div>
                <div class="estimate">
                  {{ reservationRates && reservationRates.length ?
                    `$${((reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) || {}).rate_amount || 0).toFixed(2)}` :
                    'missing rate'
                  }}
                </div>
                <div class="estimate">
                  {{ reservationRates && reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) ?
                    `$${((reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation))).rate_amount * billableHours).toLocaleString('en-US', { minimumFractionDigits: 2 })}` :
                    'missing rate'
                  }}
                </div>
              </div>
              <div class="" style="justify-content: space-between; flex-direction: row; display: flex; padding: 1rem 1rem 0rem 1rem; font-size: 16px; border-bottom: 1px dotted lightgray;">
                <div class="estimate-name">
                  {{ dateRange ? `${dateRange[0]} - ${dateRange.slice(-1)}` : 'unknown date range' }} - Equipment Rental Estimate
                </div>
                <div class="estimate">
                  {{ `$${getTotalCost().toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                </div>
                <div class="estimate">
                  {{ `$${(getTotalCost() * billableHours).toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                </div>
              </div>
              <div
                class="total-wrapper"
              >
                <div class="bold estimate-name">
                  Total Reservation Cost
                </div>
                <div class="bold estimate total-text">
                  {{ `$${(getTotalCost() + (reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) || {}).rate_amount).toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                </div>
                <div class="bold estimate total-text">
                  {{ `$${((getTotalCost() + (reservationRates.find(rate => rate.rate_service === translateReservationType(reservation.partial_reservation)) || {}).rate_amount) * billableHours).toLocaleString('en-US', {minimumFractionDigits: 2})}` }}
                </div>
              </div>
            </v-list>
            <div
              v-else
              class="text-xs-left"
            >
              No equipment added.
            </div>
            <v-btn
              v-if="!readonly"
              :disabled="reservationKey === null"
              @click="showEquipmentDialog = true"
              style="float:left;"
              data-cy="preview-edit-equipment"
            >
              edit equipment
            </v-btn>
          </div>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Vehicle Dialog -->
    <v-dialog
      v-if="!readonly"
      v-model="showVehicleDialog"
      width="50%"
    >
      <v-card data-cy="preview-dialog-vehicles">
        <VehicleForm />
        <v-btn
          @click="showVehicleDialog = false"
          data-cy="done"
        >
          Done
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- EquipmentDialog -->
    <v-dialog
      v-if="!readonly"
      v-model="showEquipmentDialog"
      width="50%"
    >
      <v-card data-cy="preview-dialog-equipment">
        <EquipmentForm :key="equipmentKey" />
        <v-btn
          @click="showEquipmentDialog = false"
          data-cy="done"
        >
          Done
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- ContactsDialog -->
    <v-dialog
      v-if="!readonly"
      v-model="showContactsDialog"
      width="50%"
    >
      <v-card data-cy="preview-dialog-contacts">
        <ContactForm :only-additional-contacts="true" />
        <v-btn
          @click="showContactsDialog = false"
          data-cy="done"
        >
          Done
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- SafetyPlanDialog -->
    <v-dialog
      v-if="!readonly"
      v-model="showSafetyPlanDialog"
      width="50%"
    >
      <SafetyPlan />
      <v-card data-cy="preview-dialog-safety-plan">
        <v-btn
          @click="finishSafetyPlan()"
          data-cy="done"
        >
          Done
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import FileDownload from './FileDownload'
import VehicleForm from './VehicleForm'
import EquipmentForm from './EquipmentForm'
import ContactForm from './ContactForm'
import SafetyPlan from './SafetyPlan'
import api from '../api'
export default {
  components: {
    FileDownload,
    VehicleForm,
    EquipmentForm,
    ContactForm,
    SafetyPlan
  },
  props: {
    disablable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    reservationCopy: null,
    modifiedOverride: false,
    showVehicleDialog: false,
    showEquipmentDialog: false,
    showContactsDialog: false,
    showSafetyPlanDialog: false,
    billableHours: 0,
    equipmentKey: 0,
    saving: false,
    increment: 0,
    duration: 0,
    grants: {},
    rules: {
      required: value => !!value || 'Required.'
    },
    dateRange: []
  }),
  mounted () {
    this.deepCopyReservation()
    if (!this.readonly) {
      // workaround
      this.reservation.primary_name = ''
      this.reservation.primary_email = ''
    }
    api.getActiveGrants().then((response) => {
      this.grants = response.data
    })
    this.billableHours = this.calculateBillingHours(this.reservation)
    this.dateRange = this.generateDateRangeArray()
    this.duration = this.dateRange.length
    this.increment = this.billableHours / this.duration
  },
  beforeDestroy () {
    this.resetReservationForm()
    this.setCurrentReservation(null)
  },
  methods: {
    ...mapActions('reservation/transactions', ['saveReservationAction', 'getReservationAction']),
    ...mapMutations('reservation', [
      'resetReservationForm',
      'setReservationForm',
      'setEquipment',
      'setCurrentReservation',
      'setCurrentEventID'
    ]),
    ...mapGetters('reservation', [
      'getSafetyPlan'
    ]),
    saveChanges () {
      this.saving = true
      this.setCurrentEventID(this.eventID)
      this.saveReservationAction().then(() => {
        this.saving = false
        this.getReservationAction(this.eventID)
      })
      this.deepCopyReservation() // to properly update modified value
      this.modifiedOverride = false
    },
    finishSafetyPlan () {
      this.showSafetyPlanDialog = false
      if (!this.unableToSave) {
        this.saveChanges()
      }
    },
    deepCopyReservation () {
      this.reservationCopy = JSON.parse(JSON.stringify(this.reservation)) // deep copy hack
    },
    maskPhoneNum (phoneNum) {
      // Assumes a 10 digit string of numbers
      return `(${phoneNum.slice(0, 3)}) ${phoneNum.slice(
        3,
        6
      )}-${phoneNum.slice(6)}`
    },
    getTotalCost () {
      let totalCost = 0
      if (this.reservation.reservation_equipment_cost) {
        for (const [, value] of Object.entries(this.reservation.reservation_equipment_cost)) {
          totalCost += value
        }
        return totalCost
      }
      return 0
    },
    calculateBillingHours (reservation) {
      // eslint-disable-next-line camelcase
      const { start_date, end_date, timeslot } = reservation
      const startDate = new Date(start_date)
      const endDate = new Date(end_date)

      let billingHours = 0
      const daysDifference = Math.ceil((endDate - startDate) / (1000 * 3600 * 24)) + 1
      if (timeslot === 'PM' || timeslot === 'AM') {
        billingHours = daysDifference * 4
      } else if (timeslot === 'FULL') {
        billingHours = daysDifference * 8
      } else if (timeslot === 'ALLDAY') {
        billingHours = daysDifference * 24
      }
      return billingHours
    },
    generateDateRangeArray () {
      // eslint-disable-next-line camelcase
      const { start_date, end_date } = this.reservation
      const startDate = new Date(start_date)
      const endDate = new Date(end_date)
      const dateArray = []

      // eslint-disable-next-line no-unmodified-loop-condition
      for (let dt = new Date(startDate); dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        const currentDate = new Date(dt)
        dateArray.push(
          currentDate.toLocaleDateString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          })
        )
      }
      return dateArray
    },
    translateReservationType (reservationType) {
      if (reservationType === 'Full') {
        return 'Whole Track'
      } else if (reservationType === 'Highway') { return 'Highway Track' } else if (reservationType === 'Urban') { return 'Urban Track' }
    }
  },
  computed: {
    ...mapState('reservation', {
      eventID: state =>
        state.currentReservation ? state.currentReservation.event_id : null,
      allEquipment: state => state.equipment,
      reservation: state => state.reservationForm,
      reservationRates: state => state.reservationForm.reservation_rates,
      safetyPlan: state =>
        state.currentReservation && state.currentReservation.safety_plan_media
          ? state.currentReservation.safety_plan_media[0]
          : null,
      reservationKey: state =>
        state.currentReservation
          ? state.currentReservation.reservation_key
          : null
    }),
    workingSafetyPlan () {
      if (this.getSafetyPlan()) {
        return this.getSafetyPlan()
      }
      return this.safetyPlan
    },
    modified () {
      return (
        this.reservationCopy &&
        JSON.stringify(this.reservation) !==
          JSON.stringify(this.reservationCopy)
      )
    },
    unableToSave () {
      return (
        this.reservation.primary_name === '' ||
        this.reservation.company_name === '' ||
        this.reservation.primary_email === '' ||
        this.reservation.primary_phone === '' ||
        this.reservation.billing_name === '' ||
        this.reservation.billing_email === '' ||
        this.reservation.billing_phone === '' ||
        this.reservation.billing_address === '' ||
        this.reservation.primary_name.length > 100 ||
        this.reservation.company_name.length > 200 ||
        this.reservation.primary_email.length > 100 ||
        this.reservation.primary_phone.length > 10 ||
        this.reservation.billing_name.length > 100 ||
        this.reservation.billing_email.length > 100 ||
        this.reservation.billing_phone.length > 10 ||
        this.reservation.billing_address.length > 300 ||
        !/^[a-zA-Z\s'-.,]*$/.test(this.reservation.primary_name) ||
        !/^[a-zA-Z\s'-.,&]*$/.test(this.reservation.company_name) ||
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.reservation.primary_email
        ) ||
        !/^[0-9]+$/.test(this.reservation.primary_phone) ||
        !/^[a-zA-Z\s'-.,]*$/.test(this.reservation.billing_name) ||
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.reservation.billing_email
        ) ||
        !/^[0-9]+$/.test(this.reservation.billing_phone) ||
        this.reservation.technician_support.length > 500 ||
        this.reservation.hardware_desc.length > 500 ||
        !/^([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(this.reservation.tech_time)
      )
    }
  },
  watch: {
    eventID () {
      this.deepCopyReservation()
    },
    showEquipmentDialog () {
      // to re-pull equipment with current timeslot
      this.setEquipment([])
      this.equipmentKey = this.equipmentKey + 1
    }
  }
}
</script>

<style scoped>

.bold {
  font-weight: 600;
}

.line-item-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px dotted lightgray;
}

.total-wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  padding-top: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.639);
}

.estimate-name {
  text-align: left;
  flex-basis: 50%;
}
.estimate {
  text-align: right;
  flex-basis: 25%;
}

</style>
