<template>
  <a
    @click="downloadResource"
    target="_blank"
    v-text="filename"
  />
</template>

<script>
import axios from 'axios'
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      config: {
        responseType: 'arraybuffer'
      }
    }
  },
  methods: {
    downloadResource () {
      axios.get(this.url, this.config)
        .then(response => {
          const href = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', this.filename)
          document.body.appendChild(link)
          link.click()
        })
    }
  }
}
</script>

<style>
</style>
