<template>
  <v-card data-cy="equipment-form">
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Equipment Rental
      </h5>
    </v-card-title>
    <v-card-text>
      <div class="equipment-grid">
        <div v-for="(equipment,ind) in allEquipment" :key="ind" class="equipment-item" :style="{ backgroundColor: equipment.equipment_desc in reservation_equipment_cost_for_display ? 'rgba(0,79,152,.165)' : '#F2F2F2' }">
          <div class="image-container" @mouseenter.native="showDetails(equipment)">
            <img :src="equipment.equipment_image_url" alt="Equipment Image" v-if="equipment.equipment_image_url" class="equipment-image">
          </div>
          <div class="details" v-if="equipment.equipment_available">
            <div style="font-size: 1.35rem; text-align: left; font-weight: 600;">
              {{ equipment.equipment_desc }}
            </div>
            <div style="font-size: 1rem; text-align: left;">
              {{ equipment.equipment_type }}
            </div>
            <div class="wrappe" style="display: flex; flex-direction: row; justify-content: space-between;">
              <div class="">
                {{ rates && rates.length ? `$${(rates.find(rate => rate.rate_service === equipment.equipment_charge) || {}).rate_amount || 0} / hr` : 'missing rate' }}
              </div>
              <div class="">
                <div class="add-remove"
                     v-if="!isItemSelected(equipment)"
                     slot="action"
                     @click="addItem(equipment)"
                     data-cy="add"
                >
                  Add
                </div>
                <div class="add-remove"
                     v-else
                     slot="action"
                     @click="removeItem(equipment)"
                     data-cy="remove"
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
          <div class="details" v-else>
            <div style="font-size: 1.35rem; text-align: left; font-weight: 600;">
              {{ equipment.equipment_desc }}
            </div>
            <div style="font-size: 1rem; text-align: left;">
              {{ equipment.equipment_type }}
            </div>
            <div class="wrappe" style="display: flex; flex-direction: row; justify-content: space-between;">
              unavailable
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-dialog
      v-model="showTechControlPanelDialog"
      max-width="1200"
    >
      <v-card>
        <v-layout justify-center>
          <v-img
            src="https://static.um.city/equipment-photo/FLIRCamera.jpg"
            max-width="600"
          />
        </v-layout>
        <v-card-title class="primary headline white--text">
          FLIR Camera Technology Control Plan
        </v-card-title>
        <v-card-text class="justify-left-children">
          <b style="color: red">Lincoln MKZ requester acknowledges that there is an export controlled camera on the vehicle (see photo), the offsite protocols for the camera listed here, as well as agreeing not to tamper with the camera in any way.</b>
          <h3>Offsite Protocol</h3>
          In the event of a crash involving the Mcity vehicle while it is in the field, actions with respect to the export controlled camera depend on the severity of the crash.
          <ol>
            <li>
              <b>Minor damage to Mcity vehicle (cracked bumper fascia, headlamp, etc)</b>:
              In this case, the vehicle should be returned to Mcity with the equipment installed, and repairs done in the Mcity garage. This scenario should have no consequences for the camera; we would leave it attached.
            </li>
            <li>
              <b>Damage to the Mcity vehicle where it is driveable, but not repairable by Mcity staff</b>:
              In this case, the vehicle should be driven back to Mcity where the camera (and all other instrumentation) will be removed. The camera would be secured in a locked cabinet in the Mcity office area. The vehicle would then be taken to a repair shop, and the instrumentation (including camera) reinstalled after its return.
            </li>
            <li>
              <b>Damage to the Mcity vehicle so it is not drivable after the accident</b>:
              In this case, a responsible Mcity staff member should be contacted immediately. Please call the Mcity front desk (734)764-2886. If no one answers the phone, please call the 24 hour number (734)548-9293. Please stay with the vehicle until an Mcity staff member arrives at the accident site. The Mcity staff member will remove the camera (and all other instrumentation) before the vehicle is removed from the scene. The equipment would be returned directly to Mcity and secured in a locked cabinet in the Mcity office area. The vehicle would then be taken to a repair shop, and the instrumentation (including camera) reinstalled after its return.
            </li>
          </ol>
          <v-card-text class="justify-left-children">
            <b>Note: All users are required to pass a Restricted Person Screening prior to the reservation date.</b>
          </v-card-text>
        </v-card-text>
      </v-card>
      <v-card-actions style="background-color: white;">
        <v-spacer />
        <v-btn
          @click="showTechControlPanelDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="acknowledgeTechControlPlan()"
          class="primary"
          data-cy="acknowledge-tech-control-plan"
        >
          Acknowledge
        </v-btn>
      </v-card-actions>
    </v-dialog>
    <v-dialog
      v-model="showTechTimeDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="primary headline white--text">
          Tech Time Rates
        </v-card-title>
        <v-card-text class="justify-left-children">
          <div class="tech-time-data">
            Please refer to the following link for Technician rates at Mcity:
          </div>
          <div class="tech-time-data">
            (Under Rate Card > Affiliate Member Rates)
          </div>
          <a href="https://mcity.umich.edu/our-work/mcity-test-facility/book/" class="tech-time-link">
            https://mcity.umich.edu/our-work/mcity-test-facility/book/
          </a>
        </v-card-text>
      </v-card>
      <v-card-actions style="background-color: white;">
        <v-spacer />
        <v-btn
          @click="showTechTimeDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          @click="acknowledgeTechTimePlan()"
          class="primary"
          data-cy="acknowledge-tech-control-plan"
        >
          Acknowledge
        </v-btn>
      </v-card-actions>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import api from '../api'

export default {
  data () {
    return {
      activeItem: {},
      showTechControlPanelDialog: false,
      showTechTimeDialog: false,
      currentItem: {},
      rates: {},
      quotedCost: {},
      equipmentCharges: []
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getEquipmentAction'
    ]),
    ...mapMutations('reservation', [
      'addEquipment',
      'addEquipmentCost',
      'addRentalCost',
      'removeEquipment',
      'removeEquipmentCost',
      'initRates'
    ]),
    async addItem (item) {
      if (item.equipment_cd === 'WMKZ') {
        this.currentItem = item
        this.showTechControlPanelDialog = true
      } else if (item.equipment_cd === 'TECH') {
        this.currentItem = item
        this.showTechTimeDialog = true
      } else {
        this.addEquipment(item.equipment_cd)
        const cost = this.rates && this.rates.length ? this.rates.find(rate => rate.rate_service === item.equipment_charge).rate_amount : 0
        this.reservationEquipmentCost[item.equipment_desc] = cost
      }
    },
    async acknowledgeTechControlPlan () {
      this.addEquipment(this.currentItem.equipment_cd)
      const cost = this.rates && this.rates.length ? this.rates.find(rate => rate.rate_service === this.currentItem.equipment_charge).rate_amount : 0
      this.reservationEquipmentCost[this.currentItem.equipment_desc] = cost
      this.showTechControlPanelDialog = false
    },
    async acknowledgeTechTimePlan () {
      this.addEquipment(this.currentItem.equipment_cd)
      const cost = this.rates && this.rates.length ? this.rates.find(rate => rate.rate_service === this.currentItem.equipment_charge).rate_amount : 0
      this.reservationEquipmentCost[this.currentItem.equipment_desc] = cost
      this.showTechTimeDialog = false
    },
    async removeItem (item) {
      this.removeEquipment(item.equipment_cd)
      this.removeEquipmentCost(item.equipment_desc)
    },
    isItemSelected (item) {
      return this.reservationEquipment.includes(item.equipment_cd)
    },
    showDetails (item) {
      this.activeItem = item
    },
    setReservationCost (item) {
      this.reservationCost = item
    },
    getMembershipTiers (date) {
      return new Promise((resolve, reject) => {
        api.getRole(date)
          .then(response => {
            this.tiers = response.data
            resolve(this.tiers)
          })
          .catch(error => {
            console.error('Error fetching membership tiers:', error)
            reject(error)
          })
      })
    },

    getRates (date) {
      return new Promise((resolve, reject) => {
        api.getRates(date)
          .then(response => {
            this.rates = response.data.filter(obj => obj.rate_membership === this.tiers.min_label)
            this.initRates(this.rates)
            resolve()
          })
          .catch(error => {
            console.error('Error fetching rates:', error)
            reject(error)
          })
      })
    }
  },
  computed: {
    ...mapState('reservation', {
      allEquipment: state => state.equipment,
      reservation: state => state.reservationForm,
      reservationRates: state => state.reservationForm.reservation_rates,
      reservationEquipment: state => state.reservationForm.reservation_equipment,
      reservationEquipmentCost: state => state.reservationForm.reservation_equipment_cost,
      reservationCost: state => state.reservationForm.reservation_cost
    }),
    ...mapState({
      session: state => state.session,
      userRoles: state => state.session.userRoles,
      userEmail: state => state.session.user.username
    }),
    reservation_equipment_cost_for_display: function () {
      // This is a computed property that returns the reservation_equipment_cost object if it is defined.
      // This protects agains the case where a reservation is assigned but no equipment is added.
      if (this.reservation.reservation_equipment_cost === undefined) {
        return {}
      }
      return this.reservation.reservation_equipment_cost
    }
  },
  created () {
    console.log('created event handling (in EquipmentForm.vue) begun.')
    this.reservation.reservation_equipment = []
    this.reservation.reservation_equipment_cost = {}

    const currentDate = new Date()
    const year = currentDate.getFullYear() - 1
    const month = currentDate.getMonth() + 1
    const formattedMonth = month < 10 ? `0${month}` : month
    const formattedDate = `${year}-${formattedMonth}`

    this.getMembershipTiers(formattedDate)
      .then(() => {
        this.getRates(formattedDate)
      })
      .then(() => {
        this.setReservationCost()
      })
      .catch(error => console.error('Error initializing component:', error))
  },
  watch: {
    allEquipment (val) {
      this.activeItem = val[0]
    }
  },
  mounted () {
    this.getEquipmentAction() // refresh every time bc reservationForm timeslot is used to check availability of equipment
  }
}
</script>

<style scoped>
.equipment-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.equipment-item {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.image-container {
  flex-basis: 80%;
  flex-grow: 1;
  width: 100%;
}

.equipment-image {
  width: 100%;
  height: auto;
  display: block;
}

.details {
  justify-content: flex-start;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}

.tech-time-link {
  font-size: 1.5rem;
}

.tech-time-data {
  font-size: 1.5rem;
}

.add-remove {
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  cursor: pointer;
  border-radius: 2px;

}

.add-remove:hover {
    background-color: #00274c;
    border: 1px solid #00274c;
    color: white;
  }

</style>
